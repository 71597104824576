.usContainer {
  width: 24.5vw;
  height: 73.11vh;
  background: #ffffff;
  border-radius: 12px;
  border: 2px solid #ff2c53;
}
.header {
  width: 100%;
  height: 15.99vh;
}
.header p {
  padding-left: 2.24vw;
}
.title {
  font-size: 1.68vw;
  font-family: semi;
  color: #140129;
  opacity: 0.8;
  padding-top: 4.16vh;
  padding-bottom: 0.91vh;
}
.desc {
  font-size: 0.98vw;
  font-family: med;
  color: #140129;
}
.body {
  width: 100%;
  padding-left: 2.24vw;
}
.btnsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20.51vw;
  margin-bottom: 5.2vh;
  margin-top: 6.24vh;
}
.btnsContainer button {
  width: 3.36vw;
  height: 3.51vh;
  border-radius: 12px;
  background-color: rgba(20, 1, 41, 0.02);
  outline: none;
  border: none;
  color: rgba(20, 1, 41, 0.6);
  font-size: 0.91vw;
  font-family: semi;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.btnsContainer button:hover {
  background: #ff2c53;
  color: #ffffff;
}
.list li {
  color: #140129;
  padding-bottom: 2.08vh;
  margin-left: 1.3vw;
  font-size: 1.12vw;
  font-family: med;
}
.price {
  width: 20.02vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6.36vh;
}
.price p {
  color: #140129;
  font-size: 2.52vw;
  font-family: med;
}
.price button {
  width: 10.99vw;
  height: 6.24vh;
  border-radius: 24px;
  background: #ff2c53;
  outline: none;
  border: none;
  color: #ffffff;
  font-size: 1.12vw;
  font-family: semi;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.price button:hover {
  transform: scale(1.1);
}
@media (max-width: 700px) {
  .usContainer {
    width: 300px;
    height: auto;
    margin-bottom: 20px;
  }
  .header p {
    padding-left: 30px;
  }
  .title {
    font-size: 22px;
  }
  .desc {
    font-size: 10px;
  }
  .body {
    padding-left: 30px;
  }
  .btnsContainer {
    width: 240px;
    margin-top: 30px;
  }
  .btnsContainer button {
    width: 40px;
    font-size: 8px;
  }
  .list li {
    font-size: 10px;
    margin-left: 15px;
  }
  .price {
    width: 220px;
    margin-bottom: 20px;
  }
  .price p {
    font-size: 24px;
  }
  .price button {
    width: 100px;
    font-size: 12px;
    height: 30px;
  }
}
