.sagaContainer {
  display: flex;
  width: 730px;
  justify-content: center;
  margin: auto;
  flex-wrap: wrap;
}
.modalMessage {
  font-size: 16px;
  color: #ffffff;
  width: 450px;
}
.toggleContainer {
  width: 208px;
  height: 62px;
  margin: auto;
  background-color: #ff2c53;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  margin-bottom: 48px;
}
.toggleContainer button {
  width: 181px;
  height: 42px;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  font-family: semi;
  border-radius: 24px;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.spanMsg {
  width: 100% !important;
}
@media (max-width: 700px) {
  .sagaContainer {
    justify-content: center;
    width: 300px;
  }
  .toggleContainer {
    width: 85px;
    height: 38px;
    padding: 0 5px;
  }
  .toggleContainer button {
    width: 75px;
    height: 30px;
    font-size: 12px;
  }
}
