.footerContainer {
  width: 100%;
  height: 52.5px;
  background: #242530;
  z-index: 10;
  overflow-x: hidden;
}
.footerContent {
  width: 77vw;
  max-width: 1100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}
.items {
  width: 85%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rights {
  color: #ffffff;
  font-family: med;
  font-weight: 500;
  font-size: 14px;
}
.item {
  color: #e0e0ff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: med;
  font-weight: 500;
  font-size: 14px;
  opacity: 0.6;
}
.social {
  height: 100%;
  width: 71.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.secRights {
  display: none;
}
@media (max-width: 1100px) {
  .footerContent {
    width: 100%;
    padding: 0 20px;
  }
  .item {
    font-size: 10px;
  }
}
@media (max-width: 700px) {
  .footerContainer {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .footerContent {
    width: 100%;
    flex-wrap: wrap;
  }
  .items {
    flex-wrap: wrap;
    width: 100%;
  }
  .items a {
    width: 100%;
    padding-bottom: 10px;
  }
  .item {
    font-size: 10px;
    width: 100%;
    margin-top: 10px;
  }
  .rights {
    font-size: 10px;
    margin-top: 10px;
  }
  .secRights {
    display: flex;
    width: 152px;
  }
  a {
    /* text-decoration: none; */
  }
  .social {
    width: 70px;
    margin-top: 10px;
    justify-content: space-around;
  }
  .made {
    font-size: 10px;
  }
}
