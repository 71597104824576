.layout {
  position: relative;
  overflow-x: hidden;
}
.plane,
.planeBg {
  position: absolute;
  top: 255vh;
  right: -200px;
  z-index: -1;
}
.planeBg {
  display: none;
}
.earth {
  position: absolute;
  top: 135vh;
  left: -250px;
}
@media (min-width: 1700px) {
  .plane {
    width: 39vw;
    height: 114vh;
  }
  .earth {
    width: 39vw;
    height: 114vh;
  }
}
@media (max-width: 700px) {
  .plane {
    display: none;
  }
  .planeBg {
    display: inline;
  }
}
