.headerContainer {
  width: 100%;
  height: 100vh;
  background-color: #2a2b36;
}
.content {
  width: 77vw;
  max-width: 1100px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.a5Container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 920px;
  text-align: center;
}
.head {
  color: #ffffff;
  width: 688px;
  font-size: 79px;
  font-family: extra;
  padding-bottom: 36px;
}
.desc {
  color: #e0e0ff;
  width: 543px;
  font-family: med;
  opacity: 0.6;
  font-size: 24px;
  width: 100%;
}
.btnsContainer {
  width: 306px;
  margin-top: 44.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btnsContainer button {
  border-radius: 24px;
  outline: none;
  font-size: 18px;
  font-family: semi;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 10;
  position: relative;
}
.btnsContainer button:hover {
  color: #e0e0ff;
}
.learn {
  background: transparent;
  border: 2px solid #ff2c53;
  width: 147px;
  height: 48px;

  color: #ff2c53;
  font-family: semi;
}
.learn:hover {
  border: 2px solid #e0e0ff;
}
.view {
  background: #ff2c53;
  border: none;
  width: 142px;
  height: 48px;

  color: #ffffff;
  font-family: semi;
}

@media (max-width: 700px) {
  .headerContainer {
    height: 100vh;
    background-size: cover;
  }
  .content {
    width: 100%;
  }
  .head {
    font-size: 30px;
    width: 100%;
  }
  .desc {
    font-size: 15px;
    width: 90%;
  }
  .btnsContainer {
    width: 220px;
  }
  .btnsContainer button {
    width: 100px;
    font-size: 12px;
    height: 40px;
  }
}
@media (min-height: 800px) {
  .headerContainer {
    height: 96.6vh;
  }
}
