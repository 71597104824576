.question {
  width: 37.8vw;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background: linear-gradient(to right bottom, #2a2b36, #2a2b36, #272833);
  padding: 0 1.68vw;
  transition: all 0.2s;
  box-shadow: 0px 5px 20px rgba(9, 13, 20, 0.4);
  margin-bottom: 2.08vh;
  flex-wrap: wrap;
}
.ques {
  font-size: 1.26vw;
  color: #ffffff;
  font-family: med;
  padding: 3.12vh 0;
  width: 85%;
  position: relative;
}
.question svg {
  cursor: pointer;
  margin: 2.8vh 0;
  z-index: 2;
  position: relative;
}
.answer {
  font-size: 0.98vw;
  color: #e0e0ff;
  font-family: med;
  padding-bottom: 3.12vh;
  transition: all 0.1s ease-in-out;
  position: relative;
  opacity: 0.6;
}
@media (max-width: 700px) {
  .question {
    width: 320px;
  }
  .ques {
    font-size: 11px;
    padding-bottom: 10px;
    height: 20px;
  }
  .answer {
    font-size: 9px;
    padding-bottom: 40px;
    line-height: 2vh;
  }
}
