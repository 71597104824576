.usContainer {
  width: 350px;
  height: 525px;
  background: #ffffff;
  border-radius: 12px;
  border: 2px solid #ff2c53;
}
.header {
  width: 100%;
  height: 154.5px;
  text-align: center;
}
.brdrContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.brdr {
  width: 25px;
  height: 4px;
  background-color: #ff2c53;
  border-radius: 20%;
  margin-top: 3px;
}
.title {
  font-size: 24px;
  font-family: semi;
  color: #140129;
  opacity: 0.8;
  padding-top: 20px;
  padding-bottom: 7px;
}

.body {
  width: 100%;
  padding-left: 32px;
}

.list li {
  color: #140129;
  padding-bottom: 8px;
  margin-left: 18.5px;
  font-size: 16px;
  font-family: med;
}
.functionality {
  font-size: 12px;
  font-family: med;
  padding-top: 10px;
}
.price {
  width: 286px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 39px;
}
.price select {
  width: 100%;
  height: 48px;
  border: 2px solid #ff2c53;
  border-radius: 20px;
  padding-left: 14.2px;
  margin-bottom: 10.3px;
  outline: none;
  font-size: 16px;
}
.priceText {
  color: #140129;
  font-size: 36px;
  font-family: bol;
  padding-left: 2.8px;
}

.priceCont {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15.3px;
}
.price button {
  width: 100%;
  height: 48px;
  border-radius: 24px;
  background: #ff2c53;
  outline: none;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-family: semi;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-top: 20px;
}
.price button:hover {
  transform: scale(1.1);
}
@media (max-width: 700px) {
  .usContainer {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  .body {
    padding-left: 15px !important;
  }
  .title {
    font-size: 22px;
  }
  .desc {
    font-size: 10px;
  }
  .list li {
    font-size: 14px;
  }
  .body {
    padding-left: 0;
  }
  .price {
    width: 265px;
    margin-bottom: 20px;
  }
  .price p {
    font-size: 24px;
  }
  .price button {
    font-size: 12px;
    height: 40px;
  }
  .price select {
    height: 40px;
    font-size: 12px;
  }
}
