.promiseContainer {
  width: 100%;
  height: auto;
  background-color: #2a2b36;
  display: flex;
  align-items: center;
  padding: 100px 0;
}
.content {
  width: 77vw;
  max-width: 1100px;
  margin: auto;
}
.header {
  text-align: center;
}
.title {
  font-size: 36px;
  color: #ffffff;
  font-family: bol;
}
.desc {
  font-size: 16px;
  color: #e0e0ff;
  opacity: 0.6;
  font-family: med;
  padding: 28px 0;
  width: 57.4vw;
  max-width: 820px;
  margin: auto;
}
.promises {
  display: flex;
  justify-content: space-between;
}
.promise {
  width: 30.5vw;
  max-width: 350px;
  text-align: center;
  margin-bottom: 30px;
}
.promiseTitle {
  font-size: 24px;
  color: #ffffff;
  padding: 25px 0 30px;
  font-family: semi;
}
.promiseDesc {
  width: 21.35vw;
  max-width: 305px;
  font-size: 16px;
  color: #e0e0ff;
  font-family: med;
  margin: auto;
}

@media (min-height: 1000px) {
  .promiseTitle {
    padding: 30px 0 35px;
  }
}
@media (max-width: 1200px) {
  .content {
    width: 100%;
  }
}
@media (max-width: 1000px) {
  .promiseTitle {
    font-size: 18px;
  }
  .promiseDesc {
    width: 100%;
    font-size: 12px;
  }
}

@media (max-width: 700px) {
  .promiseContainer {
    height: auto;
    padding: 40px;
  }
  .content {
    width: 100%;
  }
  .title {
    font-size: 26px;
  }
  .desc {
    font-size: 11px;
    padding: 15px 0 25px;
    width: 320px;
  }
  .promises {
    flex-wrap: wrap;
    justify-content: center;
  }
  .promise {
    width: 320px;
    margin-bottom: 40px;
  }
  .promiseTitle {
    font-size: 20px;
  }
  .promiseDesc {
    font-size: 12px;
    width: 260px;
  }
}
