.sideToggle {
  width: 40px;
  height: 50px;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: flex-end;
  padding: 10px 0;
  margin-right: 50px;
  cursor: pointer;
  right: -1.2rem;
  top: 0.5%;
  position: absolute;
  z-index: 99999999;
}
.sideToggle div {
  width: 2rem;
  height: 0.25rem;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
  transition: all 0.2s ease-in-out;
}
.sideToggle:hover div {
  background-color: #e0e0ff;
}

@media (min-width: 950px) {
  .sideToggle {
    display: none;
  }
}
@media (max-width: 450px) {
  .sideToggle {
    right: 1rem;
    margin-right: 15px;
  }
}
