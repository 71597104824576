.couponForm {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.couponForm input {
  font-family: med;
  text-indent: 18px;
  font-size: large;
}
.coupon {
  width: 65%;
  height: 48px;
  border-radius: 18px;
  border: 2px solid #ff2c53;
  outline: none;
  color: #140129;
}
.coupon::placeholder {
  padding-left: 10px;
}
.add {
  width: 30% !important;
  height: 48px;
  border-radius: 24px;
  background: #ff2c53;
  outline: none;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-family: semi;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-top: 0 !important;
}
.couponLabel {
  color: #140129;
  font-size: 16px;
  font-family: med;
}
.alert {
  color: #d0342c;
  font-size: 12px;
  font-family: med;
}
