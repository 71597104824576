.faqContainer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 50px;
  background-color: #2a2b36;
}
.content {
  width: 77vw;
  max-width: 1100px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.help {
  font-size: 3.36vw;
  color: #ffffff;
  padding-bottom: 6.24vh;
  font-family: extra;
}
.questions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 9.36vh;
}
@media (max-width: 700px) {
  .content {
    width: 320px;
  }
  .title {
    font-size: 12px;
  }
  .titleCont {
    width: 50px;
  }
  .help {
    font-size: 24px;
  }
}
