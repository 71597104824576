.pricingContainer {
  width: 100%;
  height: auto;
  padding-bottom: 50px;
  background-color: #2a2b36;
}

.content {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.rect {
  width: 100%;
}

.header {
  text-align: center;
}

.title {
  font-size: 45px;
  color: #ffffff;
  font-family: bol;
}

.desc {
  font-size: 16px;
  color: #e0e0ff;
  opacity: 0.6;
  font-family: med;
  padding: 28px 0;
}

.toggleContainer {
  width: 674px;
  height: 62px;
  margin: auto;
  background-color: #ff2c53;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  margin-bottom: 48px;
}

.toggleContainer button {
  width: 25%;
  height: 42px;
  /* padding-left: 1.3rem; */
  /* padding-right: 1.3rem; */
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 12px;
  font-family: semi;
  border-radius: 24px;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

@media (max-width: 850px) {
  .pricingContainer {
    height: auto;
  }
}

@media (max-width: 700px) {
  .content {
    width: 95%;
  }

  .title {
    font-size: 26px;
  }

  .desc {
    font-size: 11px;
    padding: 15px 0 25px;
  }

  .toggleContainer {
    width: 100%;
    height: 40px;
    border-radius: 24px;
    padding: 0 5px;
  }

  .toggleContainer button {
    width: 25%;
    height: 30px;
    font-size: 8px;
  }
}
