.usContainer {
  width: 350px;
  height: auto;
  background: #ffffff;
  border-radius: 12px;
  border: 2px solid #ff2c53;
  position: relative;
}
.header {
  width: 100%;
  height: 80px;
}
.header p {
  padding-left: 32px;
}
.title {
  font-size: 24px;
  font-family: semi;
  color: #140129;
  opacity: 0.8;
  padding-top: 20px;
  padding-bottom: 7px;
}
.subTitle {
  font-size: 12px;
  font-family: med;
  color: #140129;
}
.desc {
  font-size: 14px;
  font-family: med;
  color: #140129;
}
.body {
  width: 100%;
  padding-left: 32px;
}
.btns {
  width: 100%;
  display: flex;
  justify-content: center;
}
.btnsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 222px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.btnsContainer button {
  width: 48px;
  height: 27px;
  border-radius: 12px;
  background-color: rgba(20, 1, 41, 0.02);
  outline: none;
  border: none;
  color: rgba(20, 1, 41, 0.6);
  font-size: 27px;
  font-family: semi;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.btnsContainer p {
  font-family: bol;
  font-size: 25px;
}
.btnsContainer button:hover {
  background: #ff2c53;
  color: #ffffff;
}
.list {
  margin-top: 23px;
}
.list li {
  color: #140129;
  padding-bottom: 10px;
  margin-left: 18.5px;
  font-size: 16px;
  font-family: med;
}
.couponContainer {
  padding: 0 30px;
  margin-top: 20px;
}
.price {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 20px;
}
.price p {
  color: #140129;
  font-size: 36px;
  font-family: med;
}
.price button {
  width: 157px;
  height: 48px;
  border-radius: 24px;
  background: #ff2c53;
  outline: none;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-family: semi;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.price button:hover {
  transform: scale(1.1);
}
button:disabled:hover {
  background-color: rgba(20, 1, 41, 0.02);
  color: rgba(20, 1, 41, 0.6);
  cursor: not-allowed;
}
@media (max-width: 850px) {
  .usContainer {
    margin-bottom: 20px;
  }
  .list li {
    margin-left: 15px;
  }
}
@media (max-width: 700px) {
  .usContainer {
    width: 320px;
    height: auto;
  }
  .list li {
    font-size: 14px;
    margin-left: 10px;
  }
}
