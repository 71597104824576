.navigationItems {
  width: 56.76vw;
  min-width: 680px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media (min-width: 1440px) {
  .navigationItems {
    width: 820px;
  }
}
@media (max-width: 1100px) {
  .navigationItems {
    min-width: 640px;
  }
}
.navigationItems ul {
  display: flex;
  width: 48vw;
  max-width: 700px;
  min-width: 510px;
  height: 100%;
  list-style: none;
  justify-content: space-between;
  align-items: center;
}
.navigationItems li {
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  font-family: semi;
  transition: all 0.2s ease-in-out;
}
.navigationItems li:hover {
  color: #e0e0ff !important;
}

.navButton,
.navButtonRes {
  outline: none;
  border: transparent;
  width: 104px;
  height: 41px;
  background: #ff2c53;
  border-radius: 24px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-family: semi;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding-bottom: 0.2vh;
}
@media (min-height: 1000px) {
  .navButton,
  .navButtonRes {
    max-height: 80px;
  }
}

.navButtonRes {
  display: none;
}
.navigationItems button:hover {
  color: #e0e0ff;
}
a {
  text-decoration: none;
}
@media (max-width: 950px) {
  .navigationItems ul {
    width: 280px;
    flex-direction: column;
    text-align: center;
    margin-top: 20px;
    padding-right: 0;
  }
  .navButtonRes {
    width: 100px;
    height: 40px;
    display: inline;
  }

  .navigationItems li {
    width: 100%;
    margin-bottom: 50px;
    font-size: 16px;
    cursor: pointer;
    color: #ffffff;
  }
  .navigationItems ul li {
    color: #ffffff;
  }
}
@media (max-width: 450px) {
  .navigationItems ul {
    width: 100vw;
  }
}
@media (max-width: 700px) {
  .navButton {
    display: none;
  }
  .navButtonRes {
    font-size: 14px;
  }
}
