.saucedSheeeshContainer {
  display: flex;
  width: 730px;
  justify-content: center;
  margin: auto;
  flex-wrap: wrap;
}

@media (max-width: 850px) {
  .saucedSheeeshContainer {
    width: 100%;
    justify-content: center;
  }
}
@media (max-width: 700px) {
  .saucedSheeeshContainer {
    justify-content: center;
  }
}
