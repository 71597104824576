.Modal,
.purchaseModal,
.messageModal {
  position: fixed;
  z-index: 90;
  background-color: #2a2b36;
  border-radius: 12px;
  padding: 16px;
  top: 30%;
  box-sizing: border-box;
  transition: all 0.4s ease-out;
}

@media (min-width: 700px) {
  .Modal {
    left: calc(50% - 8.25vw);
    top: 25%;
  }
  .purchaseModal {
    left: calc(50% - 18.9vw);
    top: 16%;
  }
  .messageModal {
    left: calc(50% - 250px);
  }
}
@media (max-width: 700px) {
  .Modal {
    left: calc(50% - 125px);
    top: 25%;
  }
  .purchaseModal {
    left: calc(50% - 42.9vw);
    top: 16%;
  }
  .messageModal {
    left: calc(50% - 150px);
    width: 300px;
  }
}
