.sideDrawer {
  width: 280px;
  height: 100%;
  background-color: #2a2b36;
  position: fixed;
  flex-wrap: wrap;
  z-index: 1111;
  top: 0;
  right: 0;
  padding-top: 30px;
  transition: transform 0.4s ease-out;
}
.closeIcon:hover svg {
  fill: #e0e0ff;
}
.open {
  transform: translateX(0);
}
.close {
  transform: translateX(150%);
}
.logo {
  margin: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media (min-width: 950px) {
  .sideDrawer {
    display: none;
  }
}
@media (max-width: 450px) {
  .sideDrawer {
    width: 100%;
  }
  .navContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
